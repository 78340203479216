:root {
  /* Colors */
  --color-black: #212529;
  --color-gray-dark: #707070;
  --color-gray: #D8D8D8;
  --color-gray-light: #ececec;
  --color-gray-extra-light: #f6f6f6;
  --color-white: #fff;

  --color-primary-red: 15;
  --color-primary-green: 68;
  --color-primary-blue: 122;

  --color-primary: rgb(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue)
  );
  --color-primary-darkened: #003264;
  --color-primary-lightened: #1564A5;
  --color-secondary: #BE1E2D;
  --color-light: #D73C50;

  --brand-danger: #0063A7;
  --brand-disabled: var(--color-gray-light);
  --brand-success: #0063A7;
  --brand-warning: #FFA400;

  /* Typography */
  --font-family-base: 'Lato', sans-serif;
  --font-family-headings: 'Lato', sans-serif;
  --font-family-handwriting: 'Indie Flower', cursive;

  /* Nav */
  --nav-height: 3.5rem;
  --nav-height--lg: 5rem;

  /* Spinner */
  --spinner-color: var(--color-primary);
  --spinner-color-ring: rgba(
    var(--color-primary-red),
    var(--color-primary-green),
    var(--color-primary-blue),
    .3
  );

  /* Links */
  --link-color: #4A90E2;
  --link-color--hover: #175DAF;

  /* Global Alert */
  --global-alert-height: 2.5rem;
  --share-banner-height: 5rem;

  /* Lead Bar */
  --lead-bar-height: 8rem;

  /* CSC: Header */
  --header-background: var(--color-primary);
  --header-color: var(--color-light);

  /* CSC: CardList */
  --card-list-width: 540px;

  /* MAPTV: Header */
  --maptv-header-height: 64px; /* Set fixed height so we can calc step offsets */

  --color-election-center-bg: #302f2f;
}

.RequestBallotApp .LocalNav__GlobalAlert {
display: none !important;
position: unset;
}
.CheckRegistrationApp .LocalNav__GlobalAlert {
display: none !important;
position: unset;
}

@media (min-width: 576px) {
  :root {
    --maptv-header-height: 112px;
  }
}

/*turns x's in checkorx a different color from the checks*/
svg.svg-inline--fa.fa-times.fa-w-11.CheckOrXIssueResponse_icon__1kW6S.CheckOrXIssueResponse_iconNegative__2C8yX {
    color: #BE1E2E !important;
}
.MaptvApp .LocalNav__GlobalAlert {
    display: none !important;
    position: unset !important;
}