$color-primary: rgb(91, 26, 148) !default;

$color-black: #2f2f2f;
$color-gray: #d8d8d8;
$color-gray-dark: scale-color($color-gray, $lightness: -50%);
$color-gray-light: scale-color($color-gray, $lightness: 50%);
$color-gray-extra-light: scale-color($color-gray, $lightness: 80%);
$color-white: #fff;

$brand-disabled: $color-gray-light;

/* sizing */
$ec-tile-width: 20rem !default;
$ec-tile-height: 20rem !default;

$card-list-width: 540px;

$lead-bar-height: 8rem;

/* global alert */
$global-alert-height: 10rem;
$share-banner-height: 5rem;

/* nav */
$nav-height: 3.5rem;
$nav-height-lg: 5rem;

/* typography */
$font-family-handwriting: 'Indie Flower', cursive;
